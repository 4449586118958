import { gql } from '@apollo/client';

export const BLOG_HOME_QUERY = gql`
    query blogHome {
        blogCategories(withRecentPosts: true) {
            id
            slug
            title
            postsCount
            recentPosts {
                id
                slug
                title
                descriptionShort
            }
        }
    }
`;

export const BLOG_CATEGORY_PAGE_QUERY = gql`
    query blogPage($page: Int, $limit: Int, $categorySlug: String) {
        blogPage(slug: $categorySlug) {
            id
            slug
            title
            description
            staticPosts {
                id
                title
                thumbnail
                descriptionShort
                slug
            }
        }
        blogPostsPaged(page: $page, limit: $limit, categorySlug: $categorySlug) {
            collection {
                id
                slug
                title
                thumbnail
                descriptionShort
            }
            metadata {
                totalPages
            }
        }
        blogCategories(withRecentPosts: false) {
            slug
            title
            staticPosts {
                id
                title
                thumbnail
                descriptionShort
                slug
            }
        }
    }
`;

export const BLOG_ALL_CATEGORY_PAGE_QUERY = gql`
    query blogPage {
        blogPosts(limit: 16) {
            id
            slug
            title
            thumbnail
            descriptionShort
        }
        blogCategories(withRecentPosts: false) {
            slug
            title
            staticPosts {
                id
                title
                thumbnail
                descriptionShort
                slug
            }
        }
    }
`;

export const BLOG_POST_PAGE_QUERY = gql`
    query blogPost($slug: String) {
        blogPost(slug: $slug) {
            id
            slug
            title
            thumbnail
            description
            publishedAt
            author {
                description
                facebookLink
                instagramLink
                linkedinLink
                name
                photo
                publicEmail
                slug
                threadLink
                tiktokLink
                twitterLink
                youtubeLink
            }
            categories {
                id
                slug
                title
            }
            recentPosts {
                id
                slug
                title
                thumbnail
                descriptionShort
            }
            relatedPosts {
                id
                slug
                title
                thumbnail
            }
            previousPost {
                id
                slug
                title
                thumbnail
            }
            nextPost {
                id
                slug
                title
                thumbnail
            }
        }
        blogCategories(withRecentPosts: false) {
            slug
            title
            staticPosts {
                id
                title
                thumbnail
                descriptionShort
                slug
            }
        }
    }
`;

export const BLOG_AUTHOR_PAGE_QUERY = gql`
    query blogAuthor($slug: String) {
        blogAuthor(slug: $slug) {
            description
            education
            facebookLink
            featured
            instagramLink
            linkedinLink
            name
            photo
            photoDetailed
            publicEmail
            recentPosts {
                id
                slug
                title
                thumbnail
                descriptionShort
            }
            slug
            threadLink
            tiktokLink
            title
            twitterLink
            youtubeLink
        }
        blogCategories(withRecentPosts: false) {
            slug
            title
            staticPosts {
                id
                title
                thumbnail
                descriptionShort
                slug
            }
        }
    }
`;

export const BLOG_POSTS_QUERY = gql`
    query blogPosts($authorSlug: String, $categorySlug: String, $page: Int, $limit: Int) {
        blogPosts(authorSlug: $authorSlug, categorySlug: $categorySlug, page: $page, limit: $limit) {
            id
            slug
            title
            thumbnail
            descriptionShort
        }
    }
`;

export const BLOG_PAGINATION_POSTS_QUERY = gql`
    query blogPostsPaged($page: Int, $limit: Int, $offset: Int, $categorySlug: String, $authorSlug: String) {
        blogPostsPaged(page: $page, limit: $limit, offset: $offset, categorySlug: $categorySlug, authorSlug: $authorSlug) {
            collection {
                id
                slug
                title
                thumbnail
                descriptionShort
            }
            metadata {
                totalCount
                totalPages
            }
        }
    }
`;

export const BLOG_CATEGORIES_QUERY = gql`
    query blogCategories {
        blogCategories {
            slug
            title
            staticPosts {
                id
                title
                thumbnail
                descriptionShort
                slug
            }
        }
    }
`;
